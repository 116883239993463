export const getLocale = () => {
  const lang = localStorage.getItem("language") ?? "en";
  return lang === "he" ? "he-il" : "en";
};

const TOKENS = {
  en: {
    account: "Account",
    "tracker-ref-with-name": "{accountName} (Simulation)",
    "account-with-name": "Account: {accountName}",
    "statements-with-name": "{accountName} - Statements",
    "statements-ref-with-name": "{accountName} (Simulation) - Statements",
    "confirmations-with-name": "{accountName} - Confirmations",
    dashboard: "Dashboard",
    "about-us": "About us",
    faq: "FAQ",
    "gti-insights": "GTI insights",
    "back-to-top": "Back to top",
    "send-us-a-message": "SEND US A MESSAGE",
    "frequently-asked-questions": "Frequently asked questions",
    "kpi-1-info": "This yield reflects GTI's gross yield from the beginning of the year",
    "kpi-2-info": "This value reflects the return on your investment from the beginning of the current calendar year.",
    "kpi-3-info":
      "This value shows the total return on your current investment balance since the initial investment date, excluding returns on any previously redeemed amounts.",
    "kpi-4-info": "This value reflects the total value of your investment, including the principal and return amount.",
    "kpi-5-info": "This value reflects the date of the first investment in GTI.",
    "kpi-6-info": "This value reflects the principal amount currently invested, excluding any amount that has been previously redeemed.",
    "kpi-7-info":
      "This value reflects the total value of all redemptions from the initial investment date, including redemptions from the principal amount and the return on that principal amount.",
    "tracker-ref-kpi-1-info": "This yield reflects GTI's gross yield from the beginning of the year",
    "tracker-ref-kpi-2-info": "This value reflects the amount of units in your investment.",
    "tracker-ref-kpi-3-info": "This value reflects the unit price of each unit as of the effective date shown in the portal.",
    "tracker-ref-kpi-4-info": "This value reflects the total value of your investment, including the principal and return amount.",
    "tracker-ref-kpi-5-info": "This value reflects the return on your investment from the beginning of the current calendar year.",
    "tracker-ref-kpi-6-info":
      "This value shows the total return on your current investment balance since the initial investment date, excluding returns on any previously redeemed amounts.",
    "tracker-ref-kpi-7-info":
      "This value reflects the principal amount currently invested, excluding any amount that has been previously redeemed.",
    closed: "Closed",
    logout: "Logout",
    active: "Active",
    "view-all": "View all",
    "view-more": "View more",
    "values-as-of": "Values as of",
    "as-of": "as of",
    "gross-yield": "gross yield",
    "coming-soon": "coming soon",
    statements: "Statements",
    confirmations: "Confirmations",
    "no-results-for-the-selected-period": "no results for the selected period",
    monthly: "Monthly",
    quarterly: "Quarterly",
    annual: "Annual",
    latest: "Latest",
    period: "Period",
    "capital-gain": "Capital gain",
    investments: "Investments",
    redemptions: "Redemptions",
    value: "Value",
    download: "Download",
    all: "All",
    "net-value": "Net value",
    "gross-value-before-deduction-of-fees": "Gross value before deduction of fees",
    "investments-during-statement-period": "Investments during statement period",
    "redemptions-during-statement-period": "Redemptions during statement period",
    "monthly-statements-are-available-for-2022-onwards":
      "Monthly statements are available for 2022 onwards. For previous statements, choose 'Quarterly' on the buttons above.",
    "gti-yield": "GTI yield",
    "initial-investment-date": "Initial investment date",
    "principal-investment-amount": "Principal investment amount",
    "net-return": "{year} YTD Net return",
    "gross-return": "{year} YTD Gross return",
    "redeemed-amount": "Redeemed amount",
    "total-net-value": "Total Net value",
    "total-gross-value": "Total Gross value",
    "net-return-on-investment": "Net return on investment",
    "gross-return-on-investment": "Gross return on investment",
    performance: "Performance",
    "view-more-articles": "View more articles",
    "effective-date": "Effective date",
    "transaction-type": "Transaction type",
    amount: "Amount",
    "no-available-date": "There is no available data",
    redemption: "Redemption",
    investment: "Investment",
    "balance-current-view": "Balance (Current view)",
    "phone-number": "Phone number",
    "year-of-birth": "Year of birth",
    "send-authentication-code": "Send authentication code",
    login: "Login",
    "security-check": "Security check",
    new: "New",
    "performance-comparison": "Performance comparison",
    "gti-yield-on-the-selected-period": "GTI yield on the selected period ({periodFilterText}) : {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "By clicking the button above you agree to our",
    "terms-of-use": "Terms of Use",
    and: "and",
    "privacy-policy": "Privacy Policy",
    "thank-you-for-contacting": "Thank you for contacting us!",
    "we-will-be-in-touch": "We will be in touch with you shortly.",
    close: "close",
    "contact-us": "Contact us",
    "our-team-is-here": "Our team is here to help! Please fill out our Contact Us form if you have any questions or need help.",
    subject: "Subject",
    message: "Message",
    send: "Send",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "We couldn't find any relevant items for the selected period. Please review your search criteria and try again.",
    "contact-us-mobile": "Contact Us",
    processing: "Processing...",
    approved: "Approved",
    "please-provide-the-6-digit-sms": "Please provide the 6-digit password sent to:",
    "please-provide-the-6-digit-mail": "Please provide the 6-digit password sent to:",
    "expired-mobile": "The one-time password sent to your mobile has expired. Please re-send and try again.",
    "expired-mail": "The one-time password sent to your email has expired. Please re-send and try again.",
    "resend-code": "Resend code",
    "sms-not-arriving-1": "SMS not arriving?",
    "sms-not-arriving-2": " No problem, click",
    "sms-not-arriving-3": "here",
    "sms-not-arriving-4": "and we'll send it to your email",
    "mail-not-arriving-1": "Prefer to use SMS instead?",
    "mail-not-arriving-2": "No problem, click",
    "mail-not-arriving-3": "here",
    "mail-not-arriving-4": "and we'll send it to your mobile number",
    "read-more": "Read more...",
    "go-back": "Go back",
    "not-interested-gift": "Are you sure you are not interested in receiving a gift?",
    "not-interested-book": "Are you sure you are not interested in receiving a book?",
    "leave-before-submitting": "Are you sure you want to leave before submitting your choice?",
    "choose-a-gift": "Choose a gift",
    replace: "Replace",
    submit: "Submit",
    "im-not-intrested-in-a-gift": "I'm not interested in a gift",
    "im-not-intrested-in-a-book": "I'm not interested in a book",
    "thank-you-for-submit": "Thank you for submitting your choice!",
    info: "Info",
    "more-info": "More info",
    "read-the-full-article": "Read the full article",
    "insights-summary": "INSIGHTS SUMMARY",
    "view-less": "View less",
  },
  he: {
    account: "חשבון",
    "account-with-name": "חשבון: {accountName}",
    "statements-with-name": "{accountName} - דוחות תקופתיים",
    "confirmations-with-name": "{accountName} - אישורים",
    dashboard: "איזור אישי",
    "about-us": "אודות GTI",
    faq: "שאלות נפוצות",
    "gti-insights": "תובנות GTI",
    "back-to-top": "חזרה למעלה",
    "send-us-a-message": "שלח לנו הודעה",
    "frequently-asked-questions": "שאלות נפוצות",
    "kpi-1-info": "/מתחילת השנה GTI תשואה זו משקפת את התשואה הגולמית של",
    "kpi-2-info": "ערך זה משקף את התשואה על השקעתך מתחילת השנה הקלנדרית הנוכחית.",
    "kpi-3-info": "ערך זה משקף את סכום התשואה המצטברת של יתרת השקעתך הנוכחית  (לא כולל סכומים שנפדו), נכון לתאריך ההשקעה הראשונית.",
    "kpi-4-info": "ערך זה משקף את הערך הכולל של השקעתך, כולל הקרן והתשואה.",
    "kpi-5-info": "ערך זה משקף את תאריך ההשקעה הראשונה ב-GTI.",
    "kpi-6-info": "ערך זה משקף את סכום הקרן של יתרת השקעתך הנוכחית (לא כולל סכומים שנפדו).",
    "kpi-7-info": "ערך זה משקף את שוויים הכולל של סך הפדיונות ממועד ההשקעה הראשונית, לרבות פדיונות מסכומי הקרן ומהתשואה הנובעת ממנו.",
    closed: "סגור",
    logout: "להתנתק",
    active: "פעיל",
    "view-all": "צפה בהכל",
    "view-more": "ראה עוד",
    "values-as-of": "ערכים נכון ל",
    "as-of": "החל מ",
    "gross-yield": "תשואה ברוטו",
    "coming-soon": "בקרוב",
    statements: "דוחות תקופתיים",
    confirmations: "אישורים",
    "no-results-for-the-selected-period": "אין תוצאות עבור התקופה שנבחרה",
    monthly: "חודשי",
    quarterly: "רבעוני",
    annual: "שנתי",
    latest: "אחרונים",
    period: "תקופה",
    "capital-gain": "רווח הון",
    investments: "השקעות",
    redemptions: "פדיונות",
    value: "ערך",
    download: "הורד",
    all: "הכל",
    "net-value": "ערך נקי",
    "gross-value-before-deduction-of-fees": "שווי ברוטו לפני ניכוי עמלות",
    "investments-during-statement-period": "השקעות במהלך תקופת הדוח",
    "redemptions-during-statement-period": "פדיונות במהלך תקופת הדוח",
    "monthly-statements-are-available-for-2022-onwards":
      "דוחות חודשיים זמינים עבור 2022 ואילך. להצהרות קודמות, בחר 'רבעוני' בלחצנים למעלה.",
    "gti-yield": "GTI תשואת",
    "initial-investment-date": "תאריך הצטרפות",
    "principal-investment-amount": "מרכיב הקרן נטו",
    "net-return": "תשואה נטו מתחילת השנה",
    "gross-return": "תשואה ברוטו {year} YTD",
    "redeemed-amount": "סכום המשיכות הכולל",
    "total-net-value": "סכום כולל נטו",
    "total-gross-value": "ערך ברוטו כולל",
    "net-return-on-investment": "מרכיב התשואה נטו",
    "gross-return-on-investment": "תשואה ברוטו מההשקעה",
    performance: "ביצועים",
    "view-more-articles": "צפו במאמרים נוספים",
    "effective-date": "מועד תקפות",
    "transaction-type": "סוג עסקה",
    amount: "סכום",
    "no-available-date": "אין נתונים זמינים",
    redemption: "פדיון",
    investment: "השקעה",
    "balance-current-view": "יתרה (תצוגה נוכחית)",
    "phone-number": "מספר טלפון",
    "year-of-birth": "שנת לידה",
    "send-authentication-code": "שלח קוד אימות לנייד",
    login: "כניסה לאיזור האישי",
    "security-check": "בדיקת אבטחה",
    new: "חדש",
    "performance-comparison": "השוואת ביצועים",
    "gti-yield-on-the-selected-period": "תשואת GTI בתקופה שנבחרה",
    "by-clicking-the-button-above-you-agree-to-our": 'ע"י לחיצה על הכפתור מעלה הנך מסכימ/ה ל',
    "terms-of-use": "תנאי שימוש",
    and: "ו",
    "privacy-policy": "מדיניות הפרטיות",
    "thank-you-for-contacting": "תודה שפנית אלינו!",
    "we-will-be-in-touch": "ניצור איתך קשר בקרוב.",
    close: "ביטול",
    "contact-us": "יצירת קשר",
    "our-team-is-here": 'הצוות שלנו ישמח לעזור! לשאלות או לבקשת עזרה יש למלא את טופס "פנייה אלינו"',
    subject: "נושא",
    message: "הודעה",
    send: "שליחה",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "לא נמצאו פריטים רלוונטיים למועד שנבחר, יש לבחון מחדש את מאפייני החיפוש ולנסות שוב.",
    "contact-us-mobile": "צור קשר",
    processing: "מעבד...",
    approved: "מאושר",
    "please-provide-the-6-digit-sms": "נא הכנס את הקוד שנשלח למספר:",
    "please-provide-the-6-digit-mail": 'נא הכנס את הקוד שנשלח לדוא"ל:',
    "expired-mobile": "פג תוקף הסיסמה החד-פעמית שנשלחה לנייד שלך. אנא שלח שוב ונסה שוב.",
    "expired-mail": "פג תוקף הסיסמה החד-פעמית שנשלחה למייל שלך. אנא שלח שוב ונסה שוב.",
    "resend-code": "שלח קוד שוב",
    "sms-not-arriving-1": "לא קיבלת סמס?",
    "sms-not-arriving-2": "לחץ",
    "sms-not-arriving-3": "כאן",
    "sms-not-arriving-4": 'כדי לשלוח את הקוד לכתובת הדוא"ל שלך',
    "mail-not-arriving-1": "מעדיף להשתמש ב-SMS במקום?",
    "mail-not-arriving-2": "אין בעיה, לחץ",
    "mail-not-arriving-3": "כאן",
    "mail-not-arriving-4": "ואנו נשלח אותו למספר הנייד שלך",
    "read-more": "קרא עוד...",
    "go-back": "חזור",
    "not-interested-gift": "האם אתה בטוח שאתה לא מעוניין לקבל מתנה?",
    "leave-before-submitting": "האם אתה בטוח שברצונך לעזוב לפני הגשת הבחירה שלך?",
    "choose-a-gift": "בחר מתנה",
    replace: "החלף",
    submit: "שלח",
    "im-not-intrested-in-a-gift": "אני לא מעוניין במתנה",
    "thank-you-for-submit": "תודה ששלחת את בחירתך!",
    info: "מידע",
    "not-interested-book": "האם אתה בטוח שאתה לא מעוניין בקבלת ספר?",
    "im-not-intrested-in-a-book": "אני לא מעוניין בספר",
    "more-info": "מידע נוסף",
    "read-the-full-article": "קרא את המאמר המלא",
    "insights-summary": "סיכום תובנות",
    "view-less": "תראה פחות",
  },
  cs: {
    account: "Účet",
    "account-with-name": "Účet: {accountName}",
    "statements-with-name": "{accountName} - Výpisy",
    "confirmations-with-name": "{accountName} - Potvrzení",
    dashboard: "Přístrojová deska",
    "about-us": "O nás",
    faq: "FAQ",
    "gti-insights": "Statistiky GTI",
    "back-to-top": "Zpět na začátek",
    "send-us-a-message": "ZAŠLETE NÁM ZPRÁVU",
    "frequently-asked-questions": "Často kladené otázky",
    "kpi-1-info": "Tento výnos odráží hrubý výnos GTI ze začátku roku",
    "kpi-2-info": "Tato hodnota odráží návratnost vaší investice od začátku aktuálního kalendářního roku.",
    "kpi-3-info":
      "Tato hodnota odráží celkovou výši návratnosti vašeho aktuálního investičního zůstatku (bez vykoupených částek) k datu počáteční investice.",
    "kpi-4-info": "Tato hodnota odráží celkovou hodnotu vaší investice, včetně částky jistiny a výnosu.",
    "kpi-5-info": "Tato hodnota odráží datum první investice do GTI.",
    "kpi-6-info": "Tato hodnota odráží částku jistiny vašeho aktuálního investičního zůstatku (bez splacených částek).",
    "kpi-7-info":
      "Tato hodnota odráží celkovou hodnotu všech zpětných odkupů od data počáteční investice, včetně zpětných odkupů z částky jistiny a návratnosti této částky jistiny.",
    closed: "ZAVŘENO",
    logout: "Odhlásit se",
    active: "Aktivní",
    "view-all": "Zobrazit vše",
    "view-more": "Ukázat více",
    "values-as-of": "Hodnoty ke dni",
    "as-of": "do",
    "gross-yield": "hrubý výnos",
    "coming-soon": "již brzy",
    statements: "Výpisy",
    confirmations: "Potvrzení",
    "no-results-for-the-selected-period": "žádné výsledky za zvolené období",
    monthly: "Měsíční",
    quarterly: "Čtvrtletní",
    annual: "Roční",
    latest: "Nejnovější",
    period: "Doba",
    "capital-gain": "Kapitálový zisk",
    investments: "Investice",
    redemptions: "Výkupy",
    value: "Hodnota",
    download: "Stažení",
    all: "Všechno",
    "net-value": "Čistá hodnota",
    "gross-value-before-deduction-of-fees": "Hrubá hodnota před odečtením poplatků",
    "investments-during-statement-period": "Investice během období výpisu",
    "redemptions-during-statement-period": "Výplaty během období výpisu",
    "monthly-statements-are-available-for-2022-onwards":
      "Od roku 2022 jsou k dispozici měsíční výpisy. Pro předchozí výpisy zvolte 'Čtvrtletně' na tlačítkách výše.",
    "gti-yield": "Výnos GTI",
    "initial-investment-date": "Datum počáteční investice",
    "principal-investment-amount": "Výše jistiny investice",
    "net-return": "{year} YTD Čistý výnos",
    "gross-return": "Hrubý výnos",
    "redeemed-amount": "Uplatněná částka",
    "total-net-value": "Celková Čistá hodnota",
    "total-gross-value": "Celková brutto hodnota",
    "net-return-on-investment": "Čistá návratnost investic",
    "gross-return-on-investment": "Hrubá návratnost investice",
    performance: "Výkon",
    "view-more-articles": "Zobrazit další články",
    "effective-date": "Datum účinnosti",
    "transaction-type": "Typ transakce",
    amount: "Množství",
    "no-available-date": "Nejsou k dispozici žádné údaje",
    redemption: "Vykoupení",
    investment: "Investice",
    "balance-current-view": "Zůstatek (aktuální zobrazení)",
    "phone-number": "Telefonní číslo",
    "year-of-birth": "Rok narození",
    "send-authentication-code": "Odeslat ověřovací kód",
    login: "Přihlásit se",
    "security-check": "Bezpečnostní kontrola",
    new: "Nový",
    "performance-comparison": "Srovnání výkonu",
    "gti-yield-on-the-selected-period": "Výnos GTI za vybrané období ({periodFilterText}): {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "Kliknutím na tlačítko výše souhlasíte s našimi",
    "terms-of-use": "Podmínky použití",
    and: "a",
    "privacy-policy": "Zásady ochrany osobních údajů",
    "thank-you-for-contacting": "Děkujeme, že jste nás kontaktovali!",
    "we-will-be-in-touch": "Brzy se vám ozveme.",
    close: "zavřít",
    "contact-us": "Kontaktujte nás",
    "our-team-is-here":
      "Náš tým je tu, aby vám pomohl! Pokud máte nějaké dotazy nebo potřebujete pomoc, vyplňte prosím náš kontaktní formulář.",
    subject: "Předmět",
    message: "Zpráva",
    send: "Poslat",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "Pro zvolené období jsme nenašli žádné relevantní položky. Zkontrolujte prosím kritéria vyhledávání a zkuste to znovu.",
    "contact-us-mobile": "Kontaktujte nás",
    processing: "Zpracovává se...",
    approved: "Schválený",
    "please-provide-the-6-digit-sms": "Zadejte 6místné heslo zaslané na adresu:",
    "please-provide-the-6-digit-mail": "Zadejte 6místné heslo zaslané na adresu:",
    "expired-mobile": "Jednorázové heslo zaslané na váš mobil vypršelo. Odešlete prosím znovu a zkuste to znovu.",
    "expired-mail": "Jednorázové heslo zaslané na váš e-mail vypršelo. Odešlete prosím znovu a zkuste to znovu.",
    "resend-code": "Znovu odeslat kód",
    "sms-not-arriving-1": "SMS nedorazila?",
    "sms-not-arriving-2": " Žádný problém, klikni",
    "sms-not-arriving-3": "tady",
    "sms-not-arriving-4": "a my vám jej zašleme na váš e-mail",
    "mail-not-arriving-1": "Chcete místo toho používat SMS?",
    "mail-not-arriving-2": "Žádný problém, klikni",
    "mail-not-arriving-3": "tady",
    "mail-not-arriving-4": "a my vám jej zašleme na vaše mobilní číslo",
    "read-more": "Přečtěte si více...",
    "go-back": "Vraťte se",
    "not-interested-gift": "Jste si jisti, že o dárek nemáte zájem?",
    "leave-before-submitting": "Opravdu chcete před odesláním výběru odejít?",
    "choose-a-gift": "Vyberte si dárek",
    replace: "Nahradit",
    submit: "Předložit",
    "im-not-intrested-in-a-gift": "Nemám zájem o dárek",
    "thank-you-for-submit": "Děkujeme za odeslání vašeho výběru!",
    info: "Info",
    "not-interested-book": "Jste si jisti, že o knihu nemáte zájem?",
    "im-not-intrested-in-a-book": "Nemám zájem o knihu",
    "more-info": "Více informací",
    "read-the-full-article": "Přečtěte si celý článek",
    "insights-summary": "SHRNUTÍ INSTITUCÍ",
    "view-less": "Zobrazit méně",
  },
  de: {
    account: "Konto",
    "account-with-name": "Konto: {accountName}",
    "statements-with-name": "{accountName} - Aussagen",
    "confirmations-with-name": "{accountName} - Bestätigungen",
    dashboard: "Armaturenbrett",
    "about-us": "Über uns",
    faq: "FAQ",
    "gti-insights": "GTI-Einblicke",
    "back-to-top": "Zurück nach oben",
    "send-us-a-message": "SCHICK UNS EINE NACHRICHT",
    "frequently-asked-questions": "Häufig gestellte Fragen",
    "kpi-1-info": "Diese Rendite spiegelt die Bruttorendite von GTI seit Jahresbeginn wider",
    "kpi-2-info": "Dieser Wert spiegelt die Rendite Ihrer Investition ab Beginn des laufenden Kalenderjahres wider.",
    "kpi-3-info":
      "Dieser Wert spiegelt den Gesamtrenditebetrag Ihres aktuellen Anlageguthabens (ohne eingelöste Beträge) zum Zeitpunkt der Erstanlage wider.",
    "kpi-4-info": "Dieser Wert spiegelt den Gesamtwert Ihrer Anlage wider, einschließlich Kapital- und Ertragsbetrag.",
    "kpi-5-info": "Dieser Wert spiegelt das Datum der ersten Investition in GTI wider.",
    "kpi-6-info": "Dieser Wert spiegelt den Kapitalbetrag Ihres aktuellen Anlageguthabens wider (ohne eingelöste Beträge).",
    "kpi-7-info":
      "Dieser Wert spiegelt den Gesamtwert aller Rücknahmen ab dem Datum der Erstanlage wider, einschließlich Rücknahmen aus dem Kapitalbetrag und der Rendite auf diesen Kapitalbetrag.",
    closed: "Geschlossen",
    logout: "Ausloggen",
    active: "Aktiv",
    "view-all": "Alle ansehen",
    "view-more": "Mehr sehen",
    "values-as-of": "Werte ab",
    "as-of": "ab",
    "gross-yield": "Bruttoertrag",
    "coming-soon": "demnächst",
    statements: "Aussagen",
    confirmations: "Bestätigungen",
    "no-results-for-the-selected-period": "keine Ergebnisse für den ausgewählten Zeitraum",
    monthly: "Monatlich",
    quarterly: "Vierteljährlich",
    annual: "Jährlich",
    latest: "Neueste",
    period: "Zeitraum",
    "capital-gain": "Wertzuwachs",
    investments: "Investitionen",
    redemptions: "Rücknahmen",
    value: "Wert",
    download: "Herunterladen",
    all: "Alle",
    "net-value": "Nettowert",
    "gross-value-before-deduction-of-fees": "Bruttowert vor Abzug der Gebühren",
    "investments-during-statement-period": "Investitionen während des Abrechnungszeitraums",
    "redemptions-during-statement-period": "Rücknahmen während des Abrechnungszeitraums",
    "monthly-statements-are-available-for-2022-onwards":
      "Monatliche Aufstellungen sind ab 2022 verfügbar. Wählen Sie für frühere Kontoauszüge „Vierteljährlich“ auf den Schaltflächen oben.",
    "gti-yield": "GTI-Ausbeute",
    "initial-investment-date": "Erstinvestitionsdatum",
    "principal-investment-amount": "Hauptinvestitionsbetrag",
    "net-return": "{year} YTD Nettorendite",
    "gross-return": "{year} YTD Bruttorendite",
    "redeemed-amount": "Eingelöster Betrag",
    "total-net-value": "Gesamtnettowert",
    "total-gross-value": "Gesamtbruttowert",
    "net-return-on-investment": "Nettorendite der Investition",
    "gross-return-on-investment": "Bruttorendite der Investition",
    performance: "Leistung",
    "view-more-articles": "Weitere Artikel anzeigen",
    "effective-date": "Datum des Inkrafttretens",
    "transaction-type": "Art der Transaktion",
    amount: "Menge",
    "no-available-date": "Es sind keine Daten verfügbar",
    redemption: "Rückzahlung",
    investment: "Investition",
    "balance-current-view": "Guthaben (Aktuelle Ansicht)",
    "phone-number": "Telefonnummer",
    "year-of-birth": "Geburtsjahr",
    "send-authentication-code": "Authentifizierungscode senden",
    login: "Anmeldung",
    "security-check": "Sicherheitskontrolle",
    new: "Neu",
    "performance-comparison": "Leistungsvergleich",
    "gti-yield-on-the-selected-period": "GTI-Ertrag im ausgewählten Zeitraum ({periodFilterText}): {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our":
      "Durch Klicken auf die Schaltfläche oben erklären Sie sich mit unserer Einwilligung einverstanden",
    "terms-of-use": "Nutzungsbedingungen",
    and: "Und",
    "privacy-policy": "Datenschutz-Bestimmungen",
    "thank-you-for-contacting": "Danke, dass Sie uns kontaktiert haben!",
    "we-will-be-in-touch": "Wir werden uns in Kürze bei Ihnen melden.",
    close: "schließen",
    "contact-us": "Kontaktiere uns",
    "our-team-is-here":
      "Unser Team ist für Sie da! Bitte füllen Sie unser Kontaktformular aus, wenn Sie Fragen haben oder Hilfe benötigen.",
    subject: "Thema",
    message: "Nachricht",
    send: "Schicken",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "Für den ausgewählten Zeitraum konnten wir keine relevanten Artikel finden. Bitte überprüfen Sie Ihre Suchkriterien und versuchen Sie es erneut.",
    "contact-us-mobile": "Kontaktiere uns",
    processing: "Wird bearbeitet...",
    approved: "Genehmigt",
    "please-provide-the-6-digit-sms": "Bitte geben Sie das 6-stellige Passwort ein, das an folgende Adresse gesendet wurde:",
    "please-provide-the-6-digit-mail": "Bitte geben Sie das 6-stellige Passwort ein, das an folgende Adresse gesendet wurde:",
    "expired-mobile":
      "Das an Ihr Mobiltelefon gesendete Einmalpasswort ist abgelaufen. Bitte senden Sie es erneut und versuchen Sie es erneut.",
    "expired-mail": "Das an Ihre E-Mail gesendete Einmalpasswort ist abgelaufen. Bitte senden Sie es erneut und versuchen Sie es erneut.",
    "resend-code": "Code erneut senden",
    "sms-not-arriving-1": "SMS kommt nicht an?",
    "sms-not-arriving-2": " Kein Problem, klicken Sie",
    "sms-not-arriving-3": "Hier",
    "sms-not-arriving-4": "und wir senden es an Ihre E-Mail",
    "mail-not-arriving-1": "Möchten Sie stattdessen lieber SMS verwenden?",
    "mail-not-arriving-2": "Kein Problem, klicken Sie",
    "mail-not-arriving-3": "Hier",
    "mail-not-arriving-4": "und wir senden es an Ihre Handynummer",
    "read-more": "Weiterlesen...",
    "go-back": "Geh zurück",
    "not-interested-gift": "Sind Sie sicher, dass Sie kein Interesse an einem Geschenk haben?",
    "leave-before-submitting": "Sind Sie sicher, dass Sie die Seite verlassen möchten, bevor Sie Ihre Auswahl absenden?",
    "choose-a-gift": "Wählen Sie ein Geschenk",
    replace: "Ersetzen",
    submit: "Einreichen",
    "im-not-intrested-in-a-gift": "Ich habe kein Interesse an einem Geschenk",
    "thank-you-for-submit": "Vielen Dank für die Übermittlung Ihrer Wahl!",
    info: "Die Info",
    "not-interested-book": "Sind Sie sicher, dass Sie kein Interesse daran haben, ein Buch zu erhalten?",
    "im-not-intrested-in-a-book": "Ich habe kein Interesse an einem Buch",
    "more-info": "Mehr Info",
    "read-the-full-article": "Lesen Sie den vollständigen Artikel",
    "insights-summary": "ZUSAMMENFASSUNG DER EINBLICKE",
    "view-less": "Weniger anzeigen",
  },
  fr: {
    account: "Compte",
    "account-with-name": "Compte: {accountName}",
    "statements-with-name": "{accountName} - Déclarations",
    "confirmations-with-name": "{accountName} - Confirmations",
    dashboard: "Tableau de bord",
    "about-us": "À propos de nous",
    faq: "FAQ",
    "gti-insights": "Informations GTI",
    "back-to-top": "Retour au sommet",
    "send-us-a-message": "ENVOIE-NOUS UN MESSAGE",
    "frequently-asked-questions": "Questions fréquemment posées",
    "kpi-1-info": "Ce rendement reflète le rendement brut de GTI depuis le début de l'année",
    "kpi-2-info": "Cette valeur reflète le rendement de votre investissement depuis le début de l'année civile en cours.",
    "kpi-3-info":
      "Cette valeur reflète le montant du rendement global de votre solde d'investissement actuel (hors montants rachetés), à la date d'investissement initial.",
    "kpi-4-info": "Cette valeur reflète la valeur totale de votre investissement, y compris le capital et le montant du rendement.",
    "kpi-5-info": "Cette valeur reflète la date du premier investissement dans GTI.",
    "kpi-6-info": "Cette valeur reflète le montant principal de votre solde de placement actuel (sans compter les montants remboursés).",
    "kpi-7-info":
      "Cette valeur reflète la valeur totale de tous les rachats depuis la date d'investissement initiale, y compris les rachats du montant principal et le rendement de ce montant principal.",
    closed: "Fermé",
    logout: "Se déconnecter",
    active: "Actif",
    "view-all": "Voir tout",
    "view-more": "Voir plus",
    "values-as-of": "Valeurs au",
    "as-of": "dès",
    "gross-yield": "rendement brut",
    "coming-soon": "à venir",
    statements: "Déclarations",
    confirmations: "Confirmations",
    "no-results-for-the-selected-period": "aucun résultat pour la période sélectionnée",
    monthly: "Mensuel",
    quarterly: "Trimestriel",
    annual: "Annuel",
    latest: "Dernier",
    period: "Période",
    "capital-gain": "Gain en capital",
    investments: "Investissements",
    redemptions: "Rachats",
    value: "Valeur",
    download: "Télécharger",
    all: "Tous",
    "net-value": "Valeur nette",
    "gross-value-before-deduction-of-fees": "Valeur brute avant déduction des frais",
    "investments-during-statement-period": "Investissements pendant la période du relevé",
    "redemptions-during-statement-period": "Rachats pendant la période du relevé",
    "monthly-statements-are-available-for-2022-onwards":
      "Les relevés mensuels sont disponibles à partir de 2022. Pour les relevés précédents, choisissez 'Trimestriel' sur les boutons ci-dessus.",
    "gti-yield": "Rendement GTI",
    "initial-investment-date": "Date d'investissement initial",
    "principal-investment-amount": "Montant de l'investissement principal",
    "net-return": "{year} YTD Rendement net",
    "gross-return": "{year} YTD Rendement brut",
    "redeemed-amount": "Montant remboursé",
    "total-net-value": "Valeur nette totale",
    "total-gross-value": "Valeur brute totale",
    "net-return-on-investment": "Retour net sur investissement",
    "gross-return-on-investment": "Retour sur investissement brut",
    performance: "Performance",
    "view-more-articles": "Voir plus d'articles",
    "effective-date": "Date effective",
    "transaction-type": "Type de transaction",
    amount: "Montant",
    "no-available-date": "Il n'y a pas de données disponibles",
    redemption: "Rachat",
    investment: "Investissement",
    "balance-current-view": "Solde (vue actuelle)",
    "phone-number": "Numéro de téléphone",
    "year-of-birth": "Année de naissance",
    "send-authentication-code": "Envoyer le code d'authentification",
    login: "Connexion",
    "security-check": "Vérification de sécurité",
    new: "Nouveau",
    "performance-comparison": "Comparaison des performances",
    "gti-yield-on-the-selected-period": "Rendement GTI sur la période sélectionnée ({periodFilterText}) : {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "En cliquant sur le bouton ci-dessus, vous acceptez nos",
    "terms-of-use": "Conditions d'utilisation",
    and: "et",
    "privacy-policy": "politique de confidentialité",
    "thank-you-for-contacting": "Merci de nous contacter!",
    "we-will-be-in-touch": "Nous vous contacterons sous peu.",
    close: "fermer",
    "contact-us": "Contactez-nous",
    "our-team-is-here":
      "Notre équipe est là pour aider! Veuillez remplir notre formulaire Contactez-nous si vous avez des questions ou avez besoin d'aide.",
    subject: "Sujet",
    message: "Message",
    send: "Envoyer",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "Nous n'avons trouvé aucun élément pertinent pour la période sélectionnée. Veuillez revoir vos critères de recherche et réessayer.",
    "contact-us-mobile": "Contactez-nous",
    processing: "Traitement...",
    approved: "Approuvé",
    "please-provide-the-6-digit-sms": "Veuillez fournir le mot de passe à 6 chiffres envoyé à :",
    "please-provide-the-6-digit-mail": "Veuillez fournir le mot de passe à 6 chiffres envoyé à :",
    "expired-mobile": "Le mot de passe à usage unique envoyé sur votre mobile a expiré. Veuillez renvoyer et réessayer.",
    "expired-mail": "Le mot de passe à usage unique envoyé à votre adresse e-mail a expiré. Veuillez renvoyer et réessayer.",
    "resend-code": "Renvoyer le code",
    "sms-not-arriving-1": "SMS n'arrive pas ?",
    "sms-not-arriving-2": " Pas de problème, cliquez",
    "sms-not-arriving-3": "ici",
    "sms-not-arriving-4": "et nous l'enverrons à votre adresse e-mail",
    "mail-not-arriving-1": "Vous préférez utiliser les SMS à la place ?",
    "mail-not-arriving-2": "Pas de problème, cliquez",
    "mail-not-arriving-3": "ici",
    "mail-not-arriving-4": "et nous l'enverrons à votre numéro de portable",
    "read-more": "En savoir plus...",
    "go-back": "Retourner",
    "not-interested-gift": "Êtes-vous sûr de ne pas être intéressé à recevoir un cadeau ?",
    "leave-before-submitting": "Êtes-vous sûr de vouloir quitter avant de soumettre votre choix ?",
    "choose-a-gift": "Choisissez un cadeau",
    replace: "Remplacer",
    submit: "Soumettre",
    "im-not-intrested-in-a-gift": "Je ne suis pas intéressé par un cadeau",
    "thank-you-for-submit": "Merci d'avoir soumis votre choix !",
    info: "Info",
    "not-interested-book": "Êtes-vous sûr de ne pas être intéressé à recevoir un livre ?",
    "im-not-intrested-in-a-book": "Je ne suis pas intéressé par un livre",
    "more-info": "Plus d'informations",
    "read-the-full-article": "Lisez entièrement l'article",
    "insights-summary": "RÉSUMÉ DES APERÇUS",
    "view-less": "Voir moins",
  },
  pt: {
    account: "Conta",
    "account-with-name": "Conta: {accountName}",
    "statements-with-name": "{accountName} - Declarações",
    "confirmations-with-name": "{accountName} - confirmações",
    dashboard: "Painel",
    "about-us": "Sobre nós",
    faq: "Perguntas frequentes",
    "gti-insights": "Informações do GTI",
    "back-to-top": "De volta ao topo",
    "send-us-a-message": "ENVIE-NOS UMA MENSAGEM",
    "frequently-asked-questions": "Perguntas frequentes",
    "kpi-1-info": "Este rendimento reflete o rendimento bruto do GTI desde o início do ano",
    "kpi-2-info": "Esse valor reflete o retorno do seu investimento desde o início do ano civil atual.",
    "kpi-3-info":
      "Esse valor reflete o valor de retorno agregado de seu saldo de investimento atual (sem incluir valores resgatados), na data do investimento inicial.",
    "kpi-4-info": "Esse valor reflete o valor total do seu investimento, incluindo o principal e o valor do retorno.",
    "kpi-5-info": "Este valor reflete a data do primeiro investimento no GTI.",
    "kpi-6-info": "Este valor reflete o valor principal do seu saldo atual de investimento (não incluindo valores resgatados).",
    "kpi-7-info":
      "Este valor reflete o valor total de todos os resgates desde a data inicial do investimento, incluindo resgates do valor principal e o retorno sobre esse valor principal.",
    closed: "Fechado",
    logout: "Sair",
    active: "Ativo",
    "view-all": "Ver tudo",
    "view-more": "Veja mais",
    "values-as-of": "Valores a partir de",
    "as-of": "a partir de",
    "gross-yield": "rendimento bruto",
    "coming-soon": "em breve",
    statements: "Declarações",
    confirmations: "confirmações",
    "no-results-for-the-selected-period": "nenhum resultado para o período selecionado",
    monthly: "Por mês",
    quarterly: "Trimestral",
    annual: "Anual",
    latest: "Mais recente",
    period: "Período",
    "capital-gain": "Ganho de capital",
    investments: "investimentos",
    redemptions: "Resgates",
    value: "Valor",
    download: "Download",
    all: "Todos",
    "net-value": "Valor líquido",
    "gross-value-before-deduction-of-fees": "Valor bruto antes da dedução das taxas",
    "investments-during-statement-period": "Investimentos durante o período do extrato",
    "redemptions-during-statement-period": "Resgates durante o período do extrato",
    "monthly-statements-are-available-for-2022-onwards":
      "Extratos mensais estão disponíveis para 2022 em diante. Para extratos anteriores, escolha 'Trimestralmente' nos botões acima.",
    "gti-yield": "Rendimento GTI",
    "initial-investment-date": "Data de investimento inicial",
    "principal-investment-amount": "Valor do investimento principal",
    "net-return": "{year} YTD retorno líquido",
    "gross-return": "{year} YTD Retorno bruto",
    "redeemed-amount": "Valor resgatado",
    "total-net-value": "Valor líquido total",
    "total-gross-value": "Valor bruto total",
    "net-return-on-investment": "Retorno líquido do investimento",
    "gross-return-on-investment": "Retorno bruto do investimento",
    performance: "Desempenho",
    "view-more-articles": "Ver mais artigos",
    "effective-date": "Data efetiva",
    "transaction-type": "Tipo de transação",
    amount: "Quantia",
    "no-available-date": "Não há dados disponíveis",
    redemption: "Redenção",
    investment: "Investimento",
    "balance-current-view": "Saldo (visualização atual)",
    "phone-number": "Número de telefone",
    "year-of-birth": "Ano de nascimento",
    "send-authentication-code": "Enviar código de autenticação",
    login: "Conecte-se",
    "security-check": "Verificação de segurança",
    new: "Novo",
    "performance-comparison": "Comparação de desempenho",
    "gti-yield-on-the-selected-period": "Rendimento GTI no período selecionado ({periodFilterText}): {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "Ao clicar no botão acima, você concorda com nossos",
    "terms-of-use": "Termos de uso",
    and: "e",
    "privacy-policy": "política de Privacidade",
    "thank-you-for-contacting": "Obrigado por nos contatar!",
    "we-will-be-in-touch": "Entraremos em contato contigo em breve.",
    close: "fechar",
    "contact-us": "Contate-nos",
    "our-team-is-here":
      "Nossa equipe está aqui para ajudar! Por favor, preencha nosso formulário Fale Conosco se tiver alguma dúvida ou precisar de ajuda.",
    subject: "Assunto",
    message: "Mensagem",
    send: "Enviar",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "Não encontramos itens relevantes para o período selecionado. Revise seus critérios de pesquisa e tente novamente.",
    "contact-us-mobile": "Contate-nos",
    processing: "Em processamento...",
    approved: "aprovado",
    "please-provide-the-6-digit-sms": "Forneça a senha de 6 dígitos enviada para:",
    "please-provide-the-6-digit-mail": "Forneça a senha de 6 dígitos enviada para:",
    "expired-mobile": "A senha única enviada para o seu celular expirou. Por favor, reenvie e tente novamente.",
    "expired-mail": "A senha única enviada para o seu e-mail expirou. Por favor, reenvie e tente novamente.",
    "resend-code": "Reenviar código",
    "sms-not-arriving-1": "O SMS não está chegando?",
    "sms-not-arriving-2": " Sem problemas, clique",
    "sms-not-arriving-3": "aqui",
    "sms-not-arriving-4": "e enviaremos para o seu e-mail",
    "mail-not-arriving-1": "Prefere usar SMS em vez disso?",
    "mail-not-arriving-2": "Sem problemas, clique",
    "mail-not-arriving-3": "aqui",
    "mail-not-arriving-4": "e enviaremos para o seu número de celular",
    "read-more": "Consulte Mais informação...",
    "go-back": "Volte",
    "not-interested-gift": "Tem certeza de que não está interessado em receber um presente?",
    "leave-before-submitting": "Tem certeza de que deseja sair antes de enviar sua escolha?",
    "choose-a-gift": "Escolha um presente",
    replace: "Substituir",
    submit: "Enviar",
    "im-not-intrested-in-a-gift": "Eu não estou interessado em um presente",
    "thank-you-for-submit": "Obrigado por enviar sua escolha!",
    info: "Informações",
    "not-interested-book": "Tem certeza de que não está interessado em receber um livro?",
    "im-not-intrested-in-a-book": "Eu não estou interessado em um livro",
    "more-info": "Mais informações",
    "read-the-full-article": "Leia o artigo completo",
    "insights-summary": "RESUMO DE INSIGHTS",
    "view-less": "Ver menos",
  },
  "pt-br": {
    account: "Conta",
    "account-with-name": "Conta: {accountName}",
    "statements-with-name": "{accountName} - Extratos",
    "confirmations-with-name": "{accountName} - Confirmações",
    dashboard: "Painel",
    "about-us": "Sobre nós",
    faq: "Perguntas frequentes",
    "gti-insights": "Informações do GTI",
    "back-to-top": "De volta ao topo",
    "send-us-a-message": "ENVIE-NOS UMA MENSAGEM",
    "frequently-asked-questions": "Perguntas frequentes",
    "kpi-1-info": "Este rendimento reflete o rendimento bruto do GTI desde o início do ano",
    "kpi-2-info": "Esse valor reflete o retorno do seu investimento desde o início do ano civil atual.",
    "kpi-3-info":
      "Esse valor reflete o valor de retorno agregado de seu saldo de investimento atual (sem incluir valores resgatados), na data do investimento inicial.",
    "kpi-4-info": "Esse valor reflete o valor total do seu investimento, incluindo o principal e o valor do retorno.",
    "kpi-5-info": "Este valor reflete a data do primeiro investimento no GTI.",
    "kpi-6-info": "Este valor reflete o valor principal do seu saldo atual de investimento (não incluindo valores resgatados).",
    "kpi-7-info":
      "Este valor reflete o valor total de todos os resgates desde a data inicial do investimento, incluindo resgates do valor principal e o retorno sobre esse valor principal.",
    closed: "Fechado",
    logout: "Sair",
    active: "Ativo",
    "view-all": "Ver tudo",
    "view-more": "Veja mais",
    "values-as-of": "Valores a partir de",
    "as-of": "a partir de",
    "gross-yield": "rendimento bruto",
    "coming-soon": "em breve",
    statements: "Declarações",
    confirmations: "confirmações",
    "no-results-for-the-selected-period": "nenhum resultado para o período selecionado",
    monthly: "Por mês",
    quarterly: "Trimestral",
    annual: "Anual",
    latest: "Mais recente",
    period: "Período",
    "capital-gain": "Ganho de capital",
    investments: "investimentos",
    redemptions: "Resgates",
    value: "Valor",
    download: "Download",
    all: "Todos",
    "net-value": "Valor líquido",
    "gross-value-before-deduction-of-fees": "Valor bruto antes da dedução das taxas",
    "investments-during-statement-period": "Investimentos durante o período do extrato",
    "redemptions-during-statement-period": "Resgates durante o período do extrato",
    "monthly-statements-are-available-for-2022-onwards":
      "Extratos mensais estão disponíveis para 2022 em diante. Para extratos anteriores, escolha 'Trimestralmente' nos botões acima.",
    "gti-yield": "Rendimento GTI",
    "initial-investment-date": "Data de investimento inicial",
    "principal-investment-amount": "Valor do investimento principal",
    "net-return": "{year} Retorno líquido acumulado no ano",
    "gross-return": "{year} Retorno bruto acumulado no ano",
    "redeemed-amount": "Valor resgatado",
    "total-net-value": "Valor líquido total",
    "total-gross-value": "Valor bruto total",
    "net-return-on-investment": "Retorno líquido do investimento",
    "gross-return-on-investment": "Retorno bruto do investimento",
    performance: "Desempenho",
    "view-more-articles": "Ver mais artigos",
    "effective-date": "Data efetiva",
    "transaction-type": "Tipo de transação",
    amount: "Quantia",
    "no-available-date": "Não há dados disponíveis",
    redemption: "Redenção",
    investment: "Investimento",
    "balance-current-view": "Saldo (visualização atual)",
    "phone-number": "Número de telefone",
    "year-of-birth": "Ano de nascimento",
    "send-authentication-code": "Enviar código de autenticação",
    login: "Conecte-se",
    "security-check": "Verificação de segurança",
    new: "Novo",
    "performance-comparison": "Comparação de desempenho",
    "gti-yield-on-the-selected-period": "Rendimento GTI no período selecionado ({periodFilterText}): {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "Ao clicar no botão acima, você concorda com nossos",
    "terms-of-use": "Termos de uso",
    and: "e",
    "privacy-policy": "política de Privacidade",
    "thank-you-for-contacting": "Obrigado por nos contatar!",
    "we-will-be-in-touch": "Entraremos em contato contigo em breve.",
    close: "fechar",
    "contact-us": "Contate-nos",
    "our-team-is-here":
      "Nossa equipe está aqui para ajudar! Por favor, preencha nosso formulário Fale Conosco se tiver alguma dúvida ou precisar de ajuda.",
    subject: "Assunto",
    message: "Mensagem",
    send: "Enviar",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "Não encontramos itens relevantes para o período selecionado. Revise seus critérios de pesquisa e tente novamente.",
    "contact-us-mobile": "Contate-nos",
    processing: "Em processamento...",
    approved: "aprovado",
    "please-provide-the-6-digit-sms": "Forneça a senha de 6 dígitos enviada para:",
    "please-provide-the-6-digit-mail": "Forneça a senha de 6 dígitos enviada para:",
    "expired-mobile": "A senha única enviada para o seu celular expirou. Por favor, reenvie e tente novamente.",
    "expired-mail": "A senha única enviada para o seu e-mail expirou. Por favor, reenvie e tente novamente.",
    "resend-code": "Reenviar código",
    "sms-not-arriving-1": "O SMS não está chegando?",
    "sms-not-arriving-2": " Sem problemas, clique",
    "sms-not-arriving-3": "aqui",
    "sms-not-arriving-4": "e enviaremos para o seu e-mail",
    "mail-not-arriving-1": "Prefere usar SMS em vez disso?",
    "mail-not-arriving-2": "Sem problemas, clique",
    "mail-not-arriving-3": "aqui",
    "mail-not-arriving-4": "e enviaremos para o seu número de celular",
    "read-more": "Consulte Mais informação...",
    "go-back": "Volte",
    "not-interested-gift": "Tem certeza de que não está interessado em receber um presente?",
    "leave-before-submitting": "Tem certeza de que deseja sair antes de enviar sua escolha?",
    "choose-a-gift": "Escolha um presente",
    replace: "Substituir",
    submit: "Enviar",
    "im-not-intrested-in-a-gift": "Eu não estou interessado em um presente",
    "thank-you-for-submit": "Obrigado por enviar sua escolha!",
    info: "Informações",
    "not-interested-book": "Tem certeza de que não está interessado em receber um livro?",
    "im-not-intrested-in-a-book": "Eu não estou interessado em um livro",
    "more-info": "Mais informações",
    "read-the-full-article": "Leia o artigo completo",
    "insights-summary": "RESUMO DE INSIGHTS",
    "view-less": "Ver menos",
  },
  sv: {
    account: "konto",
    "account-with-name": "konto: {accountName}",
    "statements-with-name": "{accountName} - Uttalanden",
    "confirmations-with-name": "{accountName} - Bekräftelser",
    dashboard: "instrumentbräda",
    "about-us": "Om oss",
    faq: "FAQ",
    "gti-insights": "GTI-insikter",
    "back-to-top": "Tillbaka till toppen",
    "send-us-a-message": "SKICKA OSS ETT MEDDELANDE",
    "frequently-asked-questions": "Vanliga frågor",
    "kpi-1-info": "Denna direktavkastning speglar GTI:s bruttoavkastning från början av året",
    "kpi-2-info": "Detta värde återspeglar avkastningen på din investering från början av innevarande kalenderår.",
    "kpi-3-info":
      "Detta värde återspeglar det sammanlagda avkastningsbeloppet för ditt nuvarande investeringssaldo (exklusive inlösta belopp) per det första investeringsdatumet.",
    "kpi-4-info": "Detta värde återspeglar det totala värdet av din investering, inklusive kapitalbelopp och avkastningsbelopp.",
    "kpi-5-info": "Detta värde återspeglar datumet för den första investeringen i GTI.",
    "kpi-6-info": "Detta värde återspeglar huvudbeloppet för ditt nuvarande investeringssaldo (exklusive inlösta belopp).",
    "kpi-7-info":
      "Detta värde återspeglar det totala värdet av alla inlösen från den första investeringsdagen, inklusive inlösen från kapitalbeloppet och avkastningen på det kapitalbeloppet.",
    closed: "Stängd",
    logout: "Logga ut",
    active: "Aktiva",
    "view-all": "Visa alla",
    "view-more": "Visa mer",
    "values-as-of": "Värden per",
    "as-of": "från och med",
    "gross-yield": "bruttoavkastning",
    "coming-soon": "kommer snart",
    statements: "Uttalanden",
    confirmations: "Bekräftelser",
    "no-results-for-the-selected-period": "inga resultat för den valda perioden",
    monthly: "En gång i månaden",
    quarterly: "Kvartals",
    annual: "Årlig",
    latest: "Senast",
    period: "Period",
    "capital-gain": "Kapitalvinsten",
    investments: "Investeringar",
    redemptions: "Inlösen",
    value: "Värde",
    download: "Ladda ner",
    all: "Allt",
    "net-value": "Nettovärde",
    "gross-value-before-deduction-of-fees": "Bruttovärde före avdrag för avgifter",
    "investments-during-statement-period": "Investeringar under rapportperioden",
    "redemptions-during-statement-period": "Inlösen under avräkningsperioden",
    "monthly-statements-are-available-for-2022-onwards":
      "Månadsutdrag finns tillgängliga för 2022 och framåt. För tidigare uttalanden, välj 'Kvartalsvis' på knapparna ovan.",
    "gti-yield": "GTI avkastning",
    "initial-investment-date": "Inledande investeringsdatum",
    "principal-investment-amount": "Huvudsaklig investeringsbelopp",
    "net-return": "{year} YTD Nettoavkastning",
    "gross-return": "{year} YTD Bruttoavkastning",
    "redeemed-amount": "Inlöst belopp",
    "total-net-value": "Totalt nettovärde",
    "total-gross-value": "Totalt bruttovärde",
    "net-return-on-investment": "Nettoavkastning på investeringen",
    "gross-return-on-investment": "Bruttoavkastning på investeringen",
    performance: "Prestanda",
    "view-more-articles": "Se fler artiklar",
    "effective-date": "Ikraftträdande datum",
    "transaction-type": "Överföringstyp",
    amount: "Belopp",
    "no-available-date": "Det finns inga tillgängliga data",
    redemption: "Upprättelse",
    investment: "Investering",
    "balance-current-view": "Saldo (nuvarande vy)",
    "phone-number": "Telefonnummer",
    "year-of-birth": "Födelseår",
    "send-authentication-code": "Skicka autentiseringskod",
    login: "Logga in",
    "security-check": "Säkerhetskontroll",
    new: "Ny",
    "performance-comparison": "Prestandajämförelse",
    "gti-yield-on-the-selected-period": "GTI-avkastning för den valda perioden ({periodFilterText}) : {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "Genom att klicka på knappen ovan godkänner du vår",
    "terms-of-use": "Villkor",
    and: "och",
    "privacy-policy": "Integritetspolicy",
    "thank-you-for-contacting": "Tack för att du kontaktar oss!",
    "we-will-be-in-touch": "Vi kommer att kontakta dig inom kort.",
    close: "stänga",
    "contact-us": "Kontakta oss",
    "our-team-is-here":
      "Vårt team är här för att hjälpa till! Vänligen fyll i vårt kontaktformulär om du har några frågor eller behöver hjälp.",
    subject: "Ämne",
    message: "Meddelande",
    send: "Skicka",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "Vi kunde inte hitta några relevanta artiklar för den valda perioden. Granska dina sökkriterier och försök igen.",
    "contact-us-mobile": "Kontakta oss",
    processing: "Bearbetar...",
    approved: "Godkänd",
    "please-provide-the-6-digit-sms": "Ange det 6-siffriga lösenordet som skickas till:",
    "please-provide-the-6-digit-mail": "Ange det 6-siffriga lösenordet som skickas till:",
    "expired-mobile": "Engångslösenordet som skickats till din mobil har gått ut. Vänligen skicka igen och försök igen.",
    "expired-mail": "Engångslösenordet som skickades till din e-post har löpt ut. Vänligen skicka igen och försök igen.",
    "resend-code": "Återsänd koden",
    "sms-not-arriving-1": "Kommer inte SMS?",
    "sms-not-arriving-2": " Inga problem, klicka",
    "sms-not-arriving-3": "här",
    "sms-not-arriving-4": "och vi skickar det till din e-post",
    "mail-not-arriving-1": "Föredrar du att använda SMS istället?",
    "mail-not-arriving-2": "Inga problem, klicka",
    "mail-not-arriving-3": "här",
    "mail-not-arriving-4": "så skickar vi det till ditt mobilnummer",
    "read-more": "Läs mer...",
    "go-back": "Gå tillbaka",
    "not-interested-gift": "Är du säker på att du inte är intresserad av att få en present?",
    "leave-before-submitting": "Är du säker på att du vill lämna innan du skickar in ditt val?",
    "choose-a-gift": "Välj en present",
    replace: "Byta ut",
    submit: "Skicka in",
    "im-not-intrested-in-a-gift": "Jag är inte intresserad av en present",
    "thank-you-for-submit": "Tack för att du skickade in ditt val!",
    info: "Info",
    "not-interested-book": "Är du säker på att du inte är intresserad av att få en bok?",
    "im-not-intrested-in-a-book": "Jag är inte intresserad av en bok",
    "more-info": "Mer information",
    "read-the-full-article": "Läs hela artikeln",
    "insights-summary": "SAMMANFATTNING AV INSIKT",
    "view-less": "Se mindre",
  },
  es: {
    account: "Cuenta",
    "account-with-name": "Cuenta: {accountName}",
    "statements-with-name": "{accountName} - Estados de cuenta",
    "confirmations-with-name": "{accountName} - Confirmaciones",
    dashboard: "Panel",
    "about-us": "Sobre nosotros",
    faq: "Preguntas más frecuentes",
    "gti-insights": "Perspectivas de GTI",
    "back-to-top": "Volver arriba",
    "send-us-a-message": "MANDANOS UN MENSAJE",
    "frequently-asked-questions": "Preguntas frecuentes",
    "kpi-1-info": "Este rendimiento refleja el rendimiento bruto de GTI desde el comienzo del año.",
    "kpi-2-info": "Este valor refleja el rendimiento de su inversión desde el comienzo del año calendario actual.",
    "kpi-3-info":
      "Este valor refleja el monto de retorno agregado de su saldo de inversión actual (sin incluir los montos redimidos), a partir de la fecha de inversión inicial.",
    "kpi-4-info": "Este valor refleja el valor total de su inversión, incluido el capital y el monto de retorno.",
    "kpi-5-info": "Este valor refleja la fecha de la primera inversión en GTI.",
    "kpi-6-info": "Este valor refleja el monto principal de su saldo de inversión actual (sin incluir los montos redimidos).",
    "kpi-7-info":
      "Este valor refleja el valor total de todos los reembolsos desde la fecha de inversión inicial, incluidos los reembolsos del monto principal y el rendimiento de ese monto principal.",
    closed: "Cerrado",
    logout: "Cerrar sesión",
    active: "Activo",
    "view-all": "Ver todo",
    "view-more": "Ver más",
    "values-as-of": "Valores a partir de",
    "as-of": "a partir de",
    "gross-yield": "rendimiento bruto",
    "coming-soon": "muy pronto",
    statements: "Declaraciones",
    confirmations: "Confirmaciones",
    "no-results-for-the-selected-period": "no hay resultados para el período seleccionado",
    monthly: "Mensual",
    quarterly: "Trimestral",
    annual: "Anual",
    latest: "El último",
    period: "Período",
    "capital-gain": "Ganancia capital",
    investments: "Inversiones",
    redemptions: "Redenciones",
    value: "Valor",
    download: "Descargar",
    all: "Todo",
    "net-value": "Valor neto",
    "gross-value-before-deduction-of-fees": "Valor bruto antes de la deducción de tasas",
    "investments-during-statement-period": "Inversiones durante el período del estado de cuenta",
    "redemptions-during-statement-period": "Redenciones durante el período del estado de cuenta",
    "monthly-statements-are-available-for-2022-onwards":
      "Los estados de cuenta mensuales están disponibles para 2022 en adelante. Para declaraciones anteriores, elija 'Trimestral' en los botones de arriba.",
    "gti-yield": "Rendimiento GTI",
    "initial-investment-date": "Fecha de inversión inicial",
    "principal-investment-amount": "Monto principal de la inversión",
    "net-return": "{year} rendimiento neto hasta la fecha",
    "gross-return": "{year} rendimiento bruto hasta la fecha",
    "redeemed-amount": "cantidad canjeada",
    "total-net-value": "Valor neto total",
    "total-gross-value": "Valor bruto total",
    "net-return-on-investment": "Retorno neto de la inversión",
    "gross-return-on-investment": "Retorno bruto de la inversión",
    performance: "Actuación",
    "view-more-articles": "Ver más artículos",
    "effective-date": "Fecha efectiva",
    "transaction-type": "Tipo de transacción",
    amount: "Cantidad",
    "no-available-date": "No hay datos disponibles",
    redemption: "Redención",
    investment: "Inversión",
    "balance-current-view": "Saldo (vista actual)",
    "phone-number": "Número de teléfono",
    "year-of-birth": "Año de nacimiento",
    "send-authentication-code": "Enviar código de autenticación",
    login: "Acceso",
    "security-check": "Control de seguridad",
    new: "Nuevo",
    "performance-comparison": "Comparación de rendimiento",
    "gti-yield-on-the-selected-period": "Rendimiento GTI en el período seleccionado ({periodFilterText}) : {gtiYield}",
    "by-clicking-the-button-above-you-agree-to-our": "Al hacer clic en el botón de arriba, aceptas nuestros",
    "terms-of-use": "Condiciones de uso",
    and: "y",
    "privacy-policy": "política de privacidad",
    "thank-you-for-contacting": "¡Gracias por contactarnos!",
    "we-will-be-in-touch": "Nos pondremos en contacto con usted en breve.",
    close: "cerca",
    "contact-us": "Contáctenos",
    "our-team-is-here":
      "¡Nuestro equipo esta aqui para ayudar! Complete nuestro formulario de contacto si tiene alguna pregunta o necesita ayuda.",
    subject: "Sujeto",
    message: "Mensaje",
    send: "Enviar",
    "we-could-not-find-any-relevant-items-for-the-selected-period":
      "No pudimos encontrar ningún artículo relevante para el período seleccionado. Revise sus criterios de búsqueda y vuelva a intentarlo.",
    "contact-us-mobile": "Contáctenos",
    processing: "Procesando...",
    approved: "Aprobado",
    "please-provide-the-6-digit-sms": "Proporcione la contraseña de 6 dígitos enviada a:",
    "please-provide-the-6-digit-mail": "Proporcione la contraseña de 6 dígitos enviada a:",
    "expired-mobile": "La contraseña de un solo uso enviada a su móvil ha caducado. Vuelva a enviar y vuelva a intentarlo.",
    "expired-mail": "La contraseña de un solo uso enviada a su correo electrónico ha caducado. Vuelva a enviar y vuelva a intentarlo.",
    "resend-code": "Reenviar codigo",
    "sms-not-arriving-1": "¿No llegan los SMS?",
    "sms-not-arriving-2": " No hay problema, haz clic",
    "sms-not-arriving-3": "aquí",
    "sms-not-arriving-4": "y te lo enviamos a tu correo",
    "mail-not-arriving-1": "¿Prefieres usar SMS en su lugar?",
    "mail-not-arriving-2": "No hay problema, haz clic",
    "mail-not-arriving-3": "aquí",
    "mail-not-arriving-4": "y te lo enviamos a tu numero de movil",
    "read-more": "Leer más...",
    "go-back": "Regresa",
    "not-interested-gift": "¿Estás seguro de que no estás interesado en recibir un regalo?",
    "leave-before-submitting": "¿Estás seguro de que quieres salir antes de enviar tu elección?",
    "choose-a-gift": "Elige un regalo",
    replace: "Reemplazar",
    submit: "Entregar",
    "im-not-intrested-in-a-gift": "no me interesa un regalo",
    "thank-you-for-submit": "¡Gracias por enviar su elección!",
    info: "Información",
    "not-interested-book": "¿Estás seguro de que no estás interesado en recibir un libro?",
    "im-not-intrested-in-a-book": "no me interesa un libro",
    "more-info": "Más información",
    "read-the-full-article": "Lee el artículo completo",
    "insights-summary": "RESUMEN DE INFORMACIONES",
    "view-less": "Ver menos",
  },
};

export { TOKENS };
