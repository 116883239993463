import _axios from "./cmsBase";
import { getLocale } from "../utils/i18n";

class MetaService {
  async getMetaData() {
    return _axios.get(`meta-data?populate=*`);
  }

  async getContactUsTopics() {
    return _axios.get(`contact-us-topics?sort=order:asc&locale=${getLocale()}`);
  }
}

export default new MetaService();
