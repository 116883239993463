import axios from "axios";

const SERVER_URL = process.env.NEXT_PUBLIC_RUNNING_AT !== "local" ? "/cms" : "http://localhost:1337";

const config = {
  baseURL: SERVER_URL + "/api",
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
