import React, { createContext, useEffect, useState } from "react";
import MetaService from "../services/meta.service";
import { TOKENS } from "../utils/i18n.js";

export const GlobalContext = createContext();

let missingTokens = {};

export default function ContextProvider({ children }) {
  const [metaData, setMetaData] = useState(null);
  const [activeAccount, setActiveAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showConsentModal, setShowConsentModal] = useState([]);

  const [tokens, setTokens] = useState(TOKENS);
  const [lang, setLang] = useState("en");

  const setLanguage = (language) => setLang(language);

  const t = (tokenForTranslation) => {
    let token;
    let replace;

    if (typeof tokenForTranslation === "string") {
      token = tokenForTranslation;
    } else {
      token = tokenForTranslation?.token;
      replace = tokenForTranslation?.replace;
    }

    if (!token) {
      return;
    }

    if (!tokens) {
      console.log(`missing language: ${lang}:`);
      console.log(TOKENS["en"]);

      return `missing language: ${lang}`;
    }

    if (!tokens[token]) {
      missingTokens[token] = "missing";
      console.log("missing tokens:");
      console.log(missingTokens);

      // return `missing t: ${token}`;

      const capitalizedToken = token.charAt(0).toUpperCase() + token.slice(1);
      return capitalizedToken;
    }

    let text = tokens[token];

    if (replace) {
      const entries = Object.entries(replace);
      entries.forEach(([key, value]) => (text = text.replace(`{${key}}`, value)));
    }

    return text;
  };

  useEffect(() => {
    const t = TOKENS[lang];
    setTokens(t);
  }, [lang]);

  useEffect(async () => {
    try {
      const lang = localStorage.getItem("language");
      if (lang) {
        setLang(lang);
      }

      const meta = await MetaService.getMetaData();
      const data = meta?.data?.attributes;

      const finalMetaData = {
        DesktopDashboardLoginText: data.DesktopDashboardLoginText,
        DesktopLogoLoginText: data.DesktopLogoLoginText,
        MagazineTitle: data.MagazineTitle,
        DesktopDashboardLogo:
          data.DesktopDashboardLogo && data.DesktopDashboardLogo.data
            ? data.DesktopDashboardLogo.data.attributes.url
            : "/images/gtiLogo.svg",
        DesktopLogoLogin:
          data.DesktopLogoLogin && data.DesktopLogoLogin.data ? data.DesktopLogoLogin.data.attributes.url : "/images/gtiLogo.svg",
        LoginImage: data.LoginImage && data.LoginImage.data ? data.LoginImage.data.attributes.url : "/images/gtiLogo.svg",
        MobileDashboardLogo:
          data.MobileDashboardLogo && data.MobileDashboardLogo.data ? data.MobileDashboardLogo.data.attributes.url : "/images/gtiLogo.svg",
        MobileLogoLogin:
          data.MobileLogoLogin && data.MobileLogoLogin.data ? data.MobileLogoLogin.data.attributes.url : "/images/gtiLogo.svg",
      };

      setMetaData(finalMetaData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        metaData: [metaData, setMetaData],
        userData: [userData, setUserData],
        accounts: [accounts, setAccounts],
        activeAccount: [activeAccount, setActiveAccount],
        showConsentModal: [showConsentModal, setShowConsentModal],
        t,
        setLanguage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
