import "../styles/globals.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { pageview } from "../utils/googleAnalytics";
import ContextProvider, { GlobalContext } from "./globalState";
import Script from "next/script";

const AppLayout = dynamic(() => import("../components/app/Layout"));
const LoginLayout = dynamic(() => import("../components/login/loginLayout"));

const GoogleAnalyticsScript = () => {
  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
        `}
      </Script>
    </>
  );
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [metaLoaded, setMetaLoaded] = useState(false);
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    // Record page view on Google Analytics
    const routeChangeHandler = (url) => {
      pageview(url);
    };

    // Add listener on route change
    router.events.on("routeChangeComplete", routeChangeHandler);

    // Remove listener when component is destroyed
    return () => {
      router.events.off("routeChangeComplete", routeChangeHandler);
    };
  }, [router.route]);

  useEffect(async () => {
    console.log(process.env.NEXT_PUBLIC_RUNNING_AT);

    let finalMetaData = {
      DesktopDashboardLoginText: "",
      Disclaimer: "",
      DesktopLogoLoginText: "",
      MagazineTitle: "",
      DesktopDashboardLogo: "",
      DesktopLogoLogin: "",
      LoginImage: "",
      MobileDashboardLogo: "",
      MobileLogoLogin: "",
    };

    try {
      const MetaService = (await import("../services/meta.service")).default;
      const meta = await MetaService.getMetaData();
      const data = meta?.data?.attributes;
      finalMetaData = {
        DesktopDashboardLoginText: data.DesktopDashboardLoginText,
        Disclaimer: data.Disclaimer,
        DesktopLogoLoginText: data.DesktopLogoLoginText,
        MagazineTitle: data.MagazineTitle,
        DesktopDashboardLogo: data.DesktopDashboardLogo.data.attributes.url,
        DesktopLogoLogin: data.DesktopLogoLogin.data.attributes.url,
        LoginImage: data.LoginImage.data.attributes.url,
        MobileDashboardLogo: data.MobileDashboardLogo.data.attributes.url,
        MobileLogoLogin: data.MobileLogoLogin.data.attributes.url,
      };
    } catch (error) {}

    localStorage.setItem("meta", JSON.stringify(finalMetaData));
    setMetaData(finalMetaData);
    setMetaLoaded(true);
  }, []);

  const Layout = ["/", "/legal/[page]", "/support", "/404"].includes(router.pathname) ? LoginLayout : AppLayout;

  return (
    (metaLoaded && (
      <ContextProvider>
        <Layout metaData={metaData}>
          <Component {...pageProps} />
          <GoogleAnalyticsScript />
        </Layout>
      </ContextProvider>
    )) || <div />
  );
}

export default MyApp;
