export const pageview = (url) => {
  try {
    window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  } catch (error) {
    console.log(error);
  }
};

export const event = ({ action, params }) => {
  window.gtag("event", action, params);
};
